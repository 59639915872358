import { render, staticRenderFns } from "./ProblemSynthesis.vue?vue&type=template&id=017cdb21&scoped=true"
import script from "./ProblemSynthesis.vue?vue&type=script&lang=js"
export * from "./ProblemSynthesis.vue?vue&type=script&lang=js"
import style0 from "./ProblemSynthesis.vue?vue&type=style&index=0&id=017cdb21&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "017cdb21",
  null
  
)

export default component.exports